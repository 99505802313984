import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <Hero
      title="404 - Not found"
      subtitle="Unfortunately, the page you tried to reach does not exist."
    />
  </Layout>
)

export default NotFoundPage
